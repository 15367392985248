<template>
  <div ref="stepRef" class="scroll-my-4">
    <BaseCard v-if="isActive" class="flex w-[532px] flex-col gap-4 p-6">
      <div>
        <div class="flex flex-row items-center justify-between">
          <p class="text-sm text-primary">{{ label }}</p>
          <input
            class="text-primary focus:ring-0 focus:ring-offset-0"
            type="radio"
            checked
          />
        </div>
        <p class="text-2xl font-medium">{{ title }}</p>
        <p v-if="description" class="text-gray-400">
          {{ description }}
        </p>
      </div>
      <slot name="content"></slot>
      <BaseDivider />
      <div class="flex gap-2">
        <BaseButton
          v-if="!hideNextButton"
          color="primary"
          :data-cy="`next-[${label}]`"
          :disabled="disabledNextButton"
          @click="!disabledNextButton ? emit('confirm-btn') : null"
        >
          <slot name="nextButton">
            <div class="flex items-center justify-between">
              <p>Volgende</p>
              <BaseIcon class="ml-2" name="arrow-right" />
            </div>
          </slot>
        </BaseButton>
        <BaseButton
          v-if="!hideBackButton"
          color="subtle"
          class="text-primary"
          @click="emit('back-btn')"
        >
          <slot name="backButton"> Vorige </slot>
        </BaseButton>
      </div>
    </BaseCard>
    <div v-else class="flex w-[516px] flex-col gap-4">
      <BaseCard
        class="flex w-full flex-col gap-4 p-6"
        :class="isEnabled ? 'cursor-pointer' : '!bg-gray-50 !text-gray-300'"
        @click="inactiveClickHandler"
      >
        <div class="flex flex-col">
          <div class="flex items-center justify-between">
            <p class="text-xs text-gray-400">{{ label }}</p>
            <div>
              <BaseIcon
                v-if="getValidationState === 'valid'"
                name="checkbox-circle"
                filled
                class="text-primary"
              />
              <BaseIcon
                v-if="getValidationState === 'default'"
                name="checkbox-blank-circle"
                class="text-gray-300"
              />
              <BaseIcon
                v-if="getValidationState === 'error'"
                name="close-circle"
                class="text-danger-600"
              />
            </div>
          </div>
          <p class="text-lg font-medium">{{ title }}</p>
          <div
            v-if="isComplete && isEnabled"
            class="flex text-sm text-gray-400"
          >
            <slot name="summary"></slot>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from "vue"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import { useWizardStore } from "@/stores/wizardStore"
import {
  BaseCard,
  BaseButton,
  BaseIcon,
  BaseDivider,
} from "@repowerednl/ui-component-library"

const props = defineProps({
  /*
  Title is the name of the step e.g: Simulatie aanmaken, Elektriciteitsaansluiting etc
  */
  title: {
    type: String,
    required: true,
  },
  /*
  Label is: Stap followed by the number, i.e: Stap 1, Stap 2 etc
  */
  label: {
    type: String,
    required: true,
  },
  // Description is the text that is displayed below the title
  description: {
    type: String,
    default: null,
  },
  hideNextButton: {
    type: Boolean,
    default: false,
  },
  disabledNextButton: {
    type: Boolean,
    default: false,
  },
  /*
  The text to be displayed on the 'back' button.
  */
  hideBackButton: {
    type: Boolean,
    default: false,
  },
  /*
  Used to determine which step is currently active
  */
  isActive: {
    type: Boolean,
    required: true,
  },
  /*
  For determining wether to show the finished checkmark and summary of the inputfields.
  */
  isComplete: {
    type: Boolean,
    required: true,
  },
  /*
  Used to determine if the step is enabled
  */
  isEnabled: {
    type: Boolean,
    default: true,
  },
  validationState: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(["confirm-btn", "back-btn", "inactive-click"])

const wizardStore = useWizardStore()
const wizardValidationStore = useWizardValidationStore()
const stepRef = ref()

const getValidationState = computed(() => {
  if (!props.isEnabled) return ""
  if (
    !wizardValidationStore.calculationStepInvalid &&
    wizardStore.currentStep > 6
  )
    return "valid"
  if (props.validationState?.$dirty) return props.isComplete ? "valid" : "error"
  return "default"
})

function inactiveClickHandler() {
  if (props.isEnabled) {
    emit("inactive-click")
  }
}

watch([() => props.isActive, () => stepRef.value], ([isActive, element]) => {
  if (element && isActive) {
    // Allow the browser to render the component before scrolling
    nextTick(() => {
      element.scrollIntoView({ behavior: "smooth" })
    })
  }
})

defineExpose({ getValidationState })
</script>
