<template>
  <MainLayout :class="themeStore.theme" />
</template>

<script setup>
import MainLayout from "@/MainLayout.vue"
import { useThemeStore } from "@/stores/use-theme-store"

const themeStore = useThemeStore()
</script>
