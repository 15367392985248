<template>
  <div v-if="filePicked || removeToUploadState" class="flex flex-col gap-2">
    <div
      class="flex items-center justify-between rounded-lg border border-gray-100 p-4"
    >
      <div class="flex items-center gap-2">
        <BaseIcon
          class="h-[40px] w-[40px] text-primary-600"
          name="file-excel-2"
          :filled="true"
        />
        <p v-if="file" class="text-base font-medium">
          {{ file.name }}
        </p>
        <p v-else class="text-base text-gray-400">
          Verwijder om een nieuw bestand te uploaden
        </p>
      </div>
      <BaseButton color="secondary" square @click="onRemoveFile">
        <BaseIcon name="delete-bin" filled class="text-xl" />
      </BaseButton>
    </div>
  </div>
  <!-- 
    :class is used in combination with the drag events. 
    When a drag event is detected, it will set active to true.
    This is to visually inform the user by making the border and text green.
    When dragging outside of the component active is set to false
    drop.prevent stops the default handling of dropped files and does a callback instead
    that emits an event and configuration as arguments
    -->
  <BaseCard
    v-else
    :class="[active ? 'text-primary' : '']"
    class="shadow-none flex w-full flex-col gap-4 border-dashed border-gray-200 py-8"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
    @drop.prevent="onDrop"
  >
    <div class="mx-auto flex flex-col items-center gap-2">
      <BaseIcon
        :class="[active ? 'text-primary' : 'text-gray-900']"
        class="!size-8"
        name="upload-2"
        :filled="true"
      />
      <p v-if="!active">
        <span class="font-bold">Klik om te uploaden</span> of drag and drop
        bestand
      </p>
      <p v-else>Laat los om het bestand te uploaden</p>
      <p class="text-sm">Bestandstype: xlsx | Max. file size: 30MB</p>
      <!-- 
        The default styling for a file picker is not adequate. So I hid the
        inputfield and connected a corresponding label element. Used tailwind
        classes and BaseIcon component to style the element
      -->
      <input
        id="file-picker"
        name="file-picker"
        type="file"
        hidden
        @input="onFilePick"
      />
      <label
        class="bg-gray-600:active flex h-8 w-[132px] cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-lg border border-primary-500 px-4 text-sm font-medium text-primary-500 hover:bg-primary hover:text-white"
        for="file-picker"
      >
        <div class="flex items-center gap-2">
          <BaseIcon class="cursor-pointer" name="search" :filled="false" />
          Kies bestand
        </div>
      </label>
      <p v-if="errorMsg">
        <span class="text-danger">ERROR: </span>{{ errorMsg }}
      </p>
    </div>
  </BaseCard>
  <slot></slot>
</template>

<script setup>
import {
  BaseCard,
  BaseButton,
  BaseIcon,
} from "@repowerednl/ui-component-library"
import { ref, onMounted } from "vue"

const props = defineProps({
  initFile: {
    type: Object,
    default: null,
  },
  fileTypes: {
    type: Array,
    default: () => ["xls", "xlsx"],
  },
  maxFileSize: {
    type: Number,
    default: 30000000,
  },
  removeToUpload: {
    type: Boolean,
    default: false,
  },
})

const active = ref(false)
const filePicked = ref(false)
const file = ref(props.initFile)
const removeToUploadState = ref(props.removeToUpload)
const errorMsg = ref("")
const emit = defineEmits(["file-picked", "file-removed"])
let inactiveTimout = null

function setActive() {
  active.value = true
  clearTimeout(inactiveTimout)
}

function setInactive() {
  inactiveTimout = setTimeout(() => {
    active.value = false
  }, 50)
}

function onFilePick() {
  const filePicker = document.getElementById("file-picker")
  if (filePicker && filePicker.files[0]) {
    file.value = filePicker.files[0]
    if (fileChecker()) {
      emit("file-picked", file.value)
    }
  }
}

function onDrop(event) {
  setInactive()
  file.value = event.dataTransfer.files[0]
  if (fileChecker()) {
    emit("file-picked", file.value)
  }
}

function fileChecker() {
  if (isValidFile()) {
    if (file.value) {
      filePicked.value = true
      return true
    } else {
      filePicked.value = false
      return false
    }
  }
}

function onRemoveFile() {
  filePicked.value = false
  errorMsg.value = ""
  file.value = null
  removeToUploadState.value = false
  emit("file-removed")
}

function isValidFile() {
  const fileExtension = file.value.name.split(/\.(?=[^.]+$)/gm)[1]
  const isValidType = props.fileTypes.includes(fileExtension)
  const isValidSize = file.value.size <= props.maxFileSize

  if (!isValidType) {
    errorMsg.value = `Invalid file type: ${fileExtension}`
  } else if (!isValidSize) {
    errorMsg.value = `Invalid file size: ${file.value.size} bytes has exceeded ${props.maxFileSize} bytes`
  }

  return isValidType && isValidSize
}

// Component remounts and does not persist the file state.
// This evaluates if a file is present
onMounted(() => {
  file.value?.name ? (filePicked.value = true) : (filePicked.value = false)
})
</script>
