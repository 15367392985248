import { defineStore } from "pinia"
import { useResultsStore } from "@/stores/useResultsStore"
import { colors } from "@/services/themeService"
import { formatNumber } from "@/services/formatterService"
import { computed } from "vue"

export const useEnergeticStore = defineStore("resultsEnergeticStore", () => {
  const resultsStore = useResultsStore()

  const tabHeaders = [
    { name: "Huidige situatie", id: "currentSituation" },
    { name: "Nieuwe situatie", id: "newSituation" },
  ]

  const energetic = {
    axes: {
      production: {
        title: "Vermogen (kW)",
      },
    },
    series: {
      Afname: {
        label: "Afname",
        axis: "production",
        colorScale: colors.blueDianne,
        type: "bar",
      },
      Teruglevering: {
        label: "Teruglevering",
        axis: "production",
        colorScale: colors.green,
        type: "bar",
      },
      Productie: {
        label: "Productie",
        axis: "production",
        colorScale: colors.info,
        type: "bar",
      },
      "Gecontracteerd vermogen afname": {
        label: "GTV afname",
        axis: "production",
        colorScale: colors.orange,
        type: "line",
      },
      "Gecontracteerd vermogen terugleveren": {
        label: "GTV terugleveren",
        axis: "production",
        colorScale: colors.finance,
        type: "line",
      },
    },
  }

  const energyProfileData = computed(() => {
    return resultsStore.results.energetic_situation.energy_profiles_table || {}
  })

  const referenceDate = computed(() => {
    return new Date(Object.keys(energyProfileData)[0])
  })

  /**
   * Creates a data structure based on the given JSON name and availability of
   * labels.
   *
   * @param {string} jsonName - The name of the JSON key to retrieve data from.
   * @param {boolean} [labelsAvailable=false] - Determines if labels should be
   * included in the data structure.
   * @return {Array<Object>} - An array of objects representing the data
   * structure.
   */
  function createDataStructure(jsonName, labelsAvailable = false) {
    const data = resultsStore.results.energetic_situation[jsonName] || {}
    return Object.values(data).map((item, index) => {
      const key = Object.keys(data)[index]
      return {
        columns: {
          column_one: { label: key },
          column_two: { label: labelsAvailable ? "Energie" : "" },
          column_three: { label: labelsAvailable ? "Percentage" : "" },
        },
        rows:
          item.map((item) => ({
            column_one: item["Flow"],
            column_two: item["Energie (kWh)"]
              ? `${formatNumber(item["Energie (kWh)"], 0)} kWh`
              : "-",
            column_three: item["Percentage"]
              ? `${formatNumber(item["Percentage"] * 100, 0)} %`
              : "-",
          })) || [],
      }
    })
  }

  /**
   * tableData represents an array of objects that contains table data. Each
   * object has a single property 'df' that represents a DataFrame and id that
   * represents the tab id.
   *
   * @typedef {Object[]} TableData
   */
  const tableData = computed(() => {
    return [
      {
        id: "currentSituation",
        df: createDataStructure("current_flow_table", true),
        sankeyData:
          resultsStore.results.energetic_situation.current_sankey_table,
      },
      {
        id: "newSituation",
        df: createDataStructure("new_flow_table", true),
        sankeyData: resultsStore.results.energetic_situation.new_sankey_table,
      },
    ]
  })
  const batteryPower = computed(() => {
    return resultsStore.results.simulation_summary.battery_power
  })
  /**
   * Graph data for the summer and winter profile.
   * Seperate the data based on the month in the key.
   * return the data for the summer and winter profile.
   */
  const summerWinterGraph = {
    axes: {
      state_of_charge: {
        title: "State of charge",
        format: "_%",
        limits: [0, 100],
      },
      power: {
        title: "Vermogen",
        format: "_ kW",
        limits: [-batteryPower.value, batteryPower.value],
      },
    },
    series: {
      Batterij_soc: {
        label: "State of charge",
        axis: "state_of_charge",
        colorScale: colors.primary,
        rescale: 100,
      },
      Batterij_state_power: {
        label: "Vermogen",
        axis: "power",
        colorScale: colors.energy,
      },
    },
  }
  const summerProfileData = computed(() => {
    return resultsStore.results.energetic_situation.summer_table
  })
  const winterProfileData = computed(() => {
    return resultsStore.results.energetic_situation.winter_table
  })

  return {
    tabHeaders,
    tableData,
    energetic,
    energyProfileData,
    summerWinterGraph,
    summerProfileData,
    winterProfileData,
    referenceDate,
  }
})
