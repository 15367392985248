<template>
  <div class="fixed right-full top-full w-[1200px]">
    <div id="pageOne">
      <ResultsConclusion class="mb-4 !border-none !p-0 !shadow-white" />

      <ResultsBattery class="!border-none !p-0 !shadow-white" />
    </div>

    <div id="pageTwo">
      <ResultsFinance class="!border-none !p-0 !shadow-white" />
    </div>

    <BaseCard class="!border-none !p-0 !shadow-white">
      <div id="pageThree">
        <TextTitle>Energetische situatie &ndash; huidige situatie</TextTitle>
        <div
          id="energyProfile"
          class="mb-4 mt-4 rounded-xl border border-gray-100 p-4"
        >
          <div class="flex items-center justify-between">
            <TextSubtitle>Energie profiel</TextSubtitle>
          </div>
          <div class="h-[313px]">
            <TimeseriesChart
              chart-type="bar"
              :series="energeticSituation.energetic.series"
              :axes="energeticSituation.energetic.axes"
              :data="energeticSituation.energyProfileData"
              :x-limits="[
                startOfYear(energeticSituation.referenceDate),
                endOfYear(energeticSituation.referenceDate),
              ]"
              time-unit="month"
            />
          </div>
        </div>
        <div class="energetics-table mb-4">
          <BaseTable
            v-for="(table, tableIndex) in energeticSituation.tableData[0].df"
            :key="tableIndex"
            class="mb-4 overflow-hidden rounded-lg border !shadow-white"
            :rows="table.rows"
            :columns="table.columns"
          />
        </div>

        <SankeyChart
          key="currentSankey"
          chart-title="Energie Flows"
          :chart-data="energeticSituation.tableData[0]?.sankeyData"
        />
      </div>
      <div id="pageFour">
        <TextTitle class="mb-4"
          >Energetische situatie &ndash; nieuwe situatie
        </TextTitle>
        <div class="energetics-table mb-4">
          <BaseTable
            v-for="(table, tableIndex) in energeticSituation.tableData[1].df"
            :key="tableIndex"
            class="energetics-table mb-4 overflow-hidden rounded-lg border !shadow-white"
            :rows="table.rows"
            :columns="table.columns"
          />
        </div>
        <SankeyChart
          key="newSankey"
          chart-title="Energie Flows"
          :chart-data="energeticSituation.tableData[1].sankeyData"
        />
      </div>
      <div id="pageFive">
        <div class="mb-4 mt-4 rounded-xl">
          <TextSubtitle>Winter</TextSubtitle>
          <div class="h-[313px]">
            <TimeseriesChart
              chart-type="line"
              :series="energeticSituation.summerWinterGraph.series"
              :axes="energeticSituation.summerWinterGraph.axes"
              :data="energeticSituation.winterProfileData"
            />
          </div>
        </div>
        <div>
          <TextSubtitle>Zomer</TextSubtitle>
          <div class="h-[313px]">
            <TimeseriesChart
              chart-type="line"
              :series="energeticSituation.summerWinterGraph.series"
              :axes="energeticSituation.summerWinterGraph.axes"
              :data="energeticSituation.summerProfileData"
            />
          </div>
        </div>
      </div>
    </BaseCard>
    <div id="pageSix">
      <ResultsAssumptions class="!border-none !p-0 !shadow-white" />
    </div>
  </div>
</template>

<script setup>
import ResultsConclusion from "@/components/results/ResultsConclusion.vue"
import ResultsBattery from "@/components/results/ResultsBattery.vue"
import ResultsFinance from "@/components/results/ResultsFinance.vue"
import ResultsAssumptions from "@/components/results/ResultsAssumptions.vue"
import { endOfYear, startOfYear } from "date-fns"
import {
  BaseCard,
  BaseTable,
  TextSubtitle,
  TextTitle,
  TimeseriesChart,
} from "@repowerednl/ui-component-library"
import SankeyChart from "@/components/charts/SankeyChart.vue"

import { useEnergeticStore } from "@/stores/useResultsEnergeticSituationStore"
const energeticSituation = useEnergeticStore()
</script>

<style lang="postcss" scoped>
.energetics-table {
  :v-deep(table) {
    th {
      @apply bg-gray-50 text-sm;
    }

    tr {
      &:last-child {
        td {
          @apply border-none font-bold;
        }
      }
    }

    th:not(:first-child),
    td:not(:first-child) {
      @apply text-right;
      width: 200px;
    }
  }
}
</style>
