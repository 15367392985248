import useUserStore from "@/stores/userStore"

const BASE_URL = `${import.meta.env.VITE_API_URL}`

/**
 * Fetches from the API with the given path and options.
 *
 * If the response status is 401 (Unauthorized) and the request is not already a
 * retry, it will attempt to refresh the access token and retry the request. The
 * 'noRetry' parameter is used to prevent infinite loops when refreshing the
 * token.
 */
export function fetchApi(path, options, noRetry) {
  const url = `${BASE_URL}${path}`
  const { accessToken, refreshToken } = useUserStore()
  const isNewTokenRequest = path === "/v2/user/refresh-access-token/"
  const token = isNewTokenRequest ? refreshToken : accessToken
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    ...options,
  }).then((response) => {
    // Refresh access token if it's expired
    if (response.status === 401 && !noRetry && !isNewTokenRequest) {
      return useUserStore().refreshAccessToken(
        () => fetchApi(path, options, true),
        () => response,
      )
    }
    return response
  })
}

/**
 * Fetches from the API with the given path and options, but throws an error if
 * the response status is not 200.
 */
export function fetchApiCatch(path, options, toJSON = false) {
  return fetchApi(path, options)
    .then((response) => {
      if (response.ok) {
        return toJSON ? response.json() : response
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
    .catch((error) => {
      console.error(`Failed to fetch from API: '${path}' (code: ${error.code})`)
      throw error
    })
}

/**
 * Wrapper for fetchApiCatch that returns JSON rather than the raw response.
 */
export function fetchApiJson(path, options) {
  return fetchApiCatch(path, options, true)
}

/**
 * Posts to the API with the given path, body, and options. Uses fetchApi at its
 * core.
 */
export function postApi(path, body, options) {
  return fetchApi(path, {
    method: "POST",
    credentials: "same-origin",
    body: JSON.stringify(body),
    ...options,
  })
}

/**
 * Posts to the API with the given path, body, and options, but throws an error
 * if the response status is not 200.
 */
export function postApiCatch(path, body, options, toJSON = false) {
  return postApi(path, body, options)
    .then((response) => {
      if (response.ok) {
        return toJSON ? response.json() : response
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
    .catch((error) => {
      console.error(`Failed to post to API: '${path}' (code: ${error.code})`)
      throw error
    })
}

/**
 * Wrapper for postApiCatch that returns JSON rather than the raw response.
 */
export function postApiJson(path, body, options) {
  return postApiCatch(path, body, options, true)
}
