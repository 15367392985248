import forms from "@tailwindcss/forms"
/** @type {import('tailwindcss').Config} */
export default {
  content: [
    "./index.html",
    "./src/**/*.{vue,js,cjs,mjs,mdx}",
    // Include Tailwind classes used in the UI component library
    "./node_modules/@repowerednl/ui-component-library/src/**/*.{vue,js,cjs,mjs,mdx}",
  ],
  theme: {
    colors: {
      transparent: "transparent",
      current: "currentColor",
      black: "#000",
      white: "#fff",
      primary: {
        50: "var(--color-primary-50)",
        100: "var(--color-primary-100)",
        200: "var(--color-primary-200)",
        300: "var(--color-primary-300)",
        400: "var(--color-primary-400)",
        500: "var(--color-primary-500)",
        DEFAULT: "var(--color-primary-500)",
        600: "var(--color-primary-600)",
        700: "var(--color-primary-700)",
        800: "var(--color-primary-800)",
        900: "var(--color-primary-900)"
      },
      gray: {
        50: "#F3F5F6",
        100: "#DEE4E8",
        200: "#C8D3D9",
        300: "#849197",
        400: "#627076",
        500: "#3F4E55",
        DEFAULT: "#3F4E55",
        600: "#39474D",
        700: "#344146",
        800: "#2F3B40",
        900: "#2B363A",
      },
      energy: {
        50: "#FFF4CC",
        100: "#FFEFB3",
        200: "#FFE999",
        300: "#FFDE66",
        400: "#FFD333",
        500: "#FFC700",
        DEFAULT: "#FFC700",
        600: "#E8B500",
        700: "#D3A500",
        800: "#C09600",
        900: "#AF8800",
      },
      success: {
        50: "#E1EFE3",
        100: "#C8E6CE",
        200: "#AEDDB9",
        300: "#7ACA8E",
        400: "#47B863",
        500: "#13A538",
        DEFAULT: "#13A538",
        600: "#188D3C",
        700: "#1C753F",
        800: "#205D43",
        900: "#244546",
      },
      danger: {
        50: "#FBD2D2",
        100: "#F9C6C6",
        200: "#F7BABA",
        300: "#F2A2A2",
        400: "#E97171",
        500: "#D61010",
        DEFAULT: "#D61010",
        600: "#C30F0F",
        700: "#B10E0E",
        800: "#A10D0D",
        900: "#920C0C",
      },
      info: {
        50: "#FFF4CC",
        100: "#FFEFB3",
        200: "#FFE999",
        300: "#FFDE66",
        400: "#FFD333",
        500: "#FFC700",
        DEFAULT: "#FFC700",
        600: "#E8B500",
        700: "#D3A500",
        800: "#C09600",
        900: "#AF8800",
      },
      finance: {
        50: "#EAF6FE",
        100: "#CDEAF9",
        200: "#B0DEF3",
        300: "#75C5E8",
        400: "#3BACDD",
        500: "#0093D2",
        DEFAULT: "#0093D2",
        600: "#0086BF",
        700: "#007AAE",
        800: "#006F9E",
        900: "#006590",
      },
      orange: {
        50: '#fff9ec',
        100: '#fff1d3',
        200: '#ffdfa5',
        300: '#ffc76d',
        400: '#ffa332',
        500: '#ff870a',
        DEFAULT: "#ff870a",
        600: '#ff6e00',
        700: '#cc4f02',
        800: '#a13d0b',
        900: '#82350c',
      },
      green:{
        '50': '#f0fdf3',
        '100': '#dbfde3',
        '200': '#baf8c9',
        '300': '#84f19e',
        '400': '#47e16d',
        '500': '#1fc84a',
        DEFAULT: "#1fc84a",
        '600': '#13a538',
        '700': '#13822f',
        '800': '#14672a',
        '900': '#135426',
      },
      blueDianne:{
        '50': '#f3faf9',
        '100': '#d9eeec',
        '200': '#b2ddd9',
        '300': '#84c4c0',
        '400': '#5aa7a4',
        '500': '#408c8b',
        DEFAULT: '#408c8b',
        '600': '#317070',
        '700': '#2b595a',
        '800': '#244546',
        '900': '#233d3e',
      }
    },
    boxShadow: {
      sm: "0  1px  2px   0   rgba(0, 0, 0, 0.08)",
      DEFAULT:
        "0  1px  2px  -1px rgba(0, 0, 0, 0.10), 0  1px  3px  0   rgba(0, 0, 0, 0.10)",
      md: "0  2px  4px  -2px rgba(0, 0, 0, 0.05), 0  4px  6px -1px rgba(0, 0, 0, 0.10)",
      lg: "0  4px  6px   0   rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.10)",
      xl: "0 10px 10px   0   rgba(0, 0, 0, 0.04), 0 20px 25px -5px rgba(0, 0, 0, 0.10)",
      "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    },
  },
  plugins: [forms],
}
