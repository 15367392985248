<template>
  <div class="h-full">
    <div class="flex h-full flex-col overflow-hidden">
      <NavbarComponent
        class="flex-none"
        :link="link"
        :theme="theme"
        :title="title"
        :is-authenticated="userStore.isAuthenticated"
        :user-name="userStore.username"
        :menu-items="menuItems"
        @logout="logout"
      />
      <div
        v-if="userStore.isAuthenticated && demoStore.active && !demoStore.ended"
        class="z-10 flex w-full flex-col border-b border-t border-info-300 bg-info-50 py-2 text-center shadow-sm"
      >
        <p class="font-bold">Demo versie</p>
        <p class="text-xs">
          Geldig tot en met:
          {{ format(demoStore.date_ends, "d MMMM y", { locale: nl }) }}
        </p>
      </div>
      <main
        ref="scrollContainer"
        class="flex flex-1 flex-col overflow-y-auto px-10 py-6"
      >
        <!-- Error notifications -->
        <TransitionGroup
          name="notification"
          tag="ul"
          class="fixed left-4 top-16 z-50 w-[360px] space-y-4"
          :class="notificationStore.errors.length ? 'mb-6' : ''"
        >
          <li
            v-for="notification in notificationStore.errors"
            :key="notification.uid"
          >
            <ErrorComponent :notification="notification" />
          </li>
        </TransitionGroup>
        <LoadingWrapper
          :loading="userStore.loading && !userStore.accessToken"
        />

        <!-- Outside of LoadingWrapper to keep contents 'alive' -->
        <RouterView v-show="!userStore.loading || userStore.accessToken" />
        <footer
          class="mt-6 flex w-full grow flex-col items-center justify-between text-sm text-gray-300"
        >
          <!-- Push footer to bottom -->
          <div class="grow" />
          <p>
            Versie <span class="italic">{{ version }}</span>
          </p>
          <p>© {{ getYear(startOfToday()) }} Repowered</p>
        </footer>
      </main>
    </div>
    <!-- Toasts container -->
    <div class="fixed bottom-4 right-4 w-[360px] space-y-4">
      <TransitionGroup name="notification">
        <ToastComponent
          v-for="notification in notificationStore.toasts"
          :key="notification.uid"
          :notification="notification"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue"
import { storeToRefs } from "pinia"
import { useRouter, useRoute } from "vue-router"
import { startOfToday, getYear, format } from "date-fns"
import useUserStore from "@/stores/userStore.js"
import { LoadingWrapper } from "@repowerednl/ui-component-library"
import ToastComponent from "@/components/notifications/ToastComponent.vue"
import ErrorComponent from "@/components/notifications/ErrorComponent.vue"
import NavbarComponent from "@/components/navigation/NavBarComponent.vue"
import { useNotificationStore } from "@/stores/useNotificationStore.js"
import { useWizardStore } from "@/stores/wizardStore"
import { useResultsStore } from "@/stores/useResultsStore"
import { useThemeStore } from "@/stores/use-theme-store"
import { useDemoStore } from "@/stores/useDemoStore"
import useChangelogStore from "@/stores/changelogStore.js"
import { nl } from "date-fns/locale"

const demoStore = useDemoStore()
const notificationStore = useNotificationStore()
const router = useRouter()
const route = useRoute()
const themeStore = useThemeStore()
const wizardStore = useWizardStore()
const resultsStore = storeToRefs(useResultsStore())
const theme = computed(() => themeStore.theme)
const userStore = useUserStore()
const changelogStore = useChangelogStore()
// eslint-disable-next-line no-undef
const version = __APP_VERSION__ // NOSONAR
const link = { name: "simulations" }
const scrollContainer = ref(null)

const title = computed(() => {
  const routeTitles = {
    "wizard-create": wizardStore.simulation.name,
    "wizard-edit": wizardStore.simulation.name,
    results: resultsStore.results.value?.simulation_summary?.name,
  }

  return routeTitles[route.name] || ""
})

const menuItems = [
  {
    name: "simulations",
    icon: "battery",
    text: "Overzicht simulaties",
  },
  {
    name: "news",
    icon: "information",
    text: "Nieuws en contact",
  },
]

// Reset container scroll position when the route changes
watch(
  () => route.name,
  () => {
    scrollContainer.value.scrollTo(0, 0)
  },
)

watch(route, () => {
  if (userStore.isAuthenticated) {
    changelogStore.loadDateUpdated()
    demoStore.getDemoConfiguration(null, (error) => {
      notificationStore.pushError(
        "Opvragen van demo configuratie mislukt.",
        `Probeer het later opnieuw (code: ${error.code}).`,
        "demo-configuration-error",
      )
    })
  }
})

function logout() {
  userStore.logout()
  router.push({ name: "login" })
}
</script>

<style>
.notification-move {
  transition: all 0.3s ease;
}

.notification-enter-active,
.notification-leave-active {
  transition: all 0.3s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/*
  Ensure leaving items are taken out of layout flow so that moving animations
  can be calculated correctly.
*/
.notification-leave-active {
  position: absolute;
}
</style>
