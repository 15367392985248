import { defineStore } from "pinia"
import { useWizardStore } from "@/stores/wizardStore.js"
import { fetchApiJson, postApiJson } from "@/services/bstApiService"
import HTTP_STATUS from "@/helpers/httpStatusHelper.js"

export default defineStore("newSimulationStore", {
  state: () => ({
    loadingIntermediate: false,
    errorIntermediate: false,
    loadingSimulation: false,
    loadingConfiguration: false,
    loadingKeyFigures: false,
    uid: null,
    dataframes: null,
  }),
  actions: {
    submitIntermediate(onSuccess, onFail) {
      const body = { ...useWizardStore().intermediateInput }
      if (this.uid) {
        body.uid = this.uid
      }
      this.loadingIntermediate = true
      postApiJson("/v2/simulations/intermediate-results/", body)
        .then((data) => {
          this.uid = data.uid
          this.dataframes = {
            ...JSON.parse(data.current_energetic_table),
            ...JSON.parse(data.current_financial_table),
            data_availability: {
              "Beschikbaarheid van de aansluiting voor levering":
                data.offtake_availability,
              "Beschikbaarheid van de aansluiting voor teruglevering":
                data.inflow_availability,
            },
          }
          this.errorIntermediate = false
          onSuccess?.(data)
        })
        .catch((error) => {
          this.errorIntermediate = true
          onFail?.(error)
        })
        .finally(() => {
          this.loadingIntermediate = false
        })
    },
    submitSimulation(isEdit, onSuccess, onFail) {
      const body = { uid: this.uid, ...useWizardStore().simulationInput }
      const promise = isEdit
        ? postApiJson(`/v2/simulations/${this.uid}/`, body, { method: "PUT" })
        : postApiJson("/v2/simulations/", body)
      this.loadingSimulation = true
      promise
        .then((data) => {
          onSuccess?.(data)
        })
        .catch((error) => {
          onFail?.(error)
        })
        .finally(() => {
          this.loadingSimulation = false
        })
    },
    getConfiguration(uid, onSuccess, onNotFound, onFail) {
      this.loadingConfiguration = true
      fetchApiJson(`/v2/simulations/${uid}/configuration/`)
        .then((data) => {
          onSuccess?.(data)
        })
        .catch((error) => {
          if (error.code === HTTP_STATUS.NOT_FOUND) {
            onNotFound?.()
            return
          }
          onFail?.(error)
        })
        .finally(() => {
          this.loadingConfiguration = false
        })
    },
    getKeyFigures(uid, onSuccess, onFail) {
      this.loadingKeyFigures = true
      fetchApiJson(`/v2/key-figures/${uid}/`)
        .then((data) => {
          onSuccess?.(data)
        })
        .catch((error) => {
          onFail?.(error)

          // retrieve default values
          onSuccess?.()
        })
        .finally(() => {
          this.loadingKeyFigures = false
        })
    },
  },
})
