import { defineStore } from "pinia"
import { fetchApi } from "@/services/bstApiService.js"

export const useDemoStore = defineStore("demoStore", {
  state: () => ({
    active: false,
    ended: true,
    date_ends: "2050-01-01T00:00:00.000Z",
    simulation_quotum_exceeded: false,
  }),
  actions: {
    getDemoConfiguration(onSuccess, onFail) {
      // Use fetchApi to prevent the 404 response from being logged
      fetchApi("/v2/user/demo/")
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            const error = new Error(response.statusText)
            error.code = response.status
            throw error
          }
        })
        .then((data) => {
          this.active = true
          this.date_ends = data.date_ends
          this.simulation_quotum_exceeded = data.simulation_quotum_exceeded
          this.ended = data.ended
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (error.code === 404) {
            this.active = false
            this.ended = false
          } else {
            console.error(
              `Failed to fetch from API: '/v2/user/demo/' (code: ${error.code})`,
            )
            if (onFail) onFail(error)
          }
        })
    },
  },
})
