<template>
  <BaseCard class="p-6">
    <TextTitle>Conclusie</TextTitle>
    <TextDescription> Financiële haalbaarheid van de batterij </TextDescription>

    <div
      v-if="resultsStore.results && resultsStore.results.conclusive_finances"
      class="my-4 grid grid-cols-3 gap-4"
    >
      <BaseCard class="!bg-primary p-4 !text-white">
        <IconContainer>
          <BaseIcon class="text-primary" name="euro" />
        </IconContainer>

        <CardContent
          :title="
            toCurrency(resultsStore.results.conclusive_finances.annual_savings)
          "
          content="Verwachte jaarlijkse besparing"
          title-suffix="per jaar"
          class="mt-2"
        >
          <template #contentSuffix>
            <WikiTooltipComponent
              path="/bst/results/annual-savings"
              color="white"
            />
          </template>
        </CardContent>
      </BaseCard>
      <BaseCard class="!bg-primary p-4 !text-white">
        <IconContainer>
          <BaseIcon class="text-primary" name="calendar" filled />
        </IconContainer>
        <CardContent
          :title="`${toDecimal(resultsStore.results.conclusive_finances.payback_period, 1)} jaar`"
          content="Terugverdientijd"
          class="mt-2"
        >
          <template #contentSuffix>
            <WikiTooltipComponent path="/bst/results/roi" color="white" />
          </template>
        </CardContent>
      </BaseCard>
      <BaseCard class="!bg-primary p-4 !text-white">
        <IconContainer>
          <BaseIcon class="text-primary" name="line-chart" filled />
        </IconContainer>
        <CardContent
          :title="
            toCurrency(
              resultsStore.results.conclusive_finances.expected_total_savings,
            )
          "
          content="Verwachte totale besparing"
          class="mt-2"
        >
          <template #contentSuffix>
            <WikiTooltipComponent
              path="/bst/results/total-savings"
              color="white"
            />
          </template>
        </CardContent>
      </BaseCard>
    </div>

    <div
      class="mb-4 grid grid-cols-3 gap-4 rounded-xl border border-gray-100 py-4"
    >
      <CardContent
        :title="
          toCurrency(resultsStore.results.conclusive_finances.total_investment)
        "
        class="px-4"
        content="Investering in totaal"
      >
        <template #contentSuffix>
          <WikiTooltipComponent path="/bst/results/investment" />
        </template>
      </CardContent>
      <CardContent
        class="px-4"
        content="Resterende cycli na terugverdientijd"
        :title="
          Math.ceil(
            resultsStore.results.battery_cycles.total_cycles -
              resultsStore.results.battery_cycles.per_year *
                resultsStore.results.conclusive_finances.payback_period,
          )
        "
      >
        <template #contentSuffix>
          <WikiTooltipComponent path="/bst/results/remaining-cycles" />
        </template>
      </CardContent>
      <CardContent
        :title="
          toCurrencyWithDigits(
            resultsStore.results.conclusive_finances.depreciation_per_year,
          )
        "
        class="px-4"
        content="Afschrijving per jaar"
        title-suffix="jaar"
      >
        <template #contentSuffix>
          <WikiTooltipComponent path="/bst/results/depreciation" />
        </template>
      </CardContent>
    </div>

    <div class="grid grid-cols-3 gap-4 rounded-xl border border-gray-100 py-4">
      <CardContent
        :title="
          toCurrency(
            resultsStore.results.conclusive_finances.current_energy_bill,
          )
        "
        class="px-4"
        content="Huidige energierekening"
        title-suffix="per jaar"
      >
        <template #contentSuffix>
          <WikiTooltipComponent path="/bst/results/current-bill" />
        </template>
      </CardContent>
      <CardContent
        :title="`${toCurrency(resultsStore.results.conclusive_finances.new_energy_bill)}`"
        class="px-4"
        content="Nieuwe energierekening"
        title-suffix="per jaar"
      >
        <template #contentSuffix>
          <WikiTooltipComponent path="/bst/results/new-bill" />
        </template>
      </CardContent>
    </div>
  </BaseCard>
</template>

<script setup>
import {
  BaseCard,
  BaseIcon,
  TextTitle,
  TextDescription,
} from "@repowerednl/ui-component-library"
import CardContent from "@/components/CardContent.vue"
import { useResultsStore } from "@/stores/useResultsStore"
import {
  toCurrency,
  toCurrencyWithDigits,
  toDecimal,
} from "@/helpers/currency-helper"
import IconContainer from "@/components/general/IconContainer.vue"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const resultsStore = useResultsStore()
</script>
