<template>
  <div
    class="relative rounded-lg border border-info-300 bg-info-50 p-4 text-gray-500"
  >
    <div class="flex flex-col gap-1">
      <!-- Message -->
      <div class="flex items-center gap-2">
        <BaseIcon name="error-warning" filled class="flex-0 text-lg" />
        <p class="flex-1 font-bold">{{ notification.title }}</p>
      </div>
      <p v-if="notification.message" class="text-sm">
        {{ notification.message }}
      </p>
      <!-- Action -->
      <BaseButton
        v-if="notification.action"
        color="outline-dark"
        small
        class="mt-1 w-fit"
        @click="notification.action"
      >
        {{ notification.actionLabel ?? "Meer informatie" }}
      </BaseButton>
    </div>
    <!-- Close -->
    <BaseButton
      color="subtle"
      square
      small
      class="!absolute right-2 top-2"
      @click="hide"
    >
      <BaseIcon name="close" class="text-xl" />
    </BaseButton>
    <!-- Show count -->
    <div
      v-if="notification.count > 1"
      class="absolute -left-1 -top-1 flex size-6 items-center justify-center rounded-full bg-gray text-sm text-white"
    >
      {{ notification.count }}
    </div>
  </div>
</template>

<script setup>
import { useNotificationStore } from "@/stores/useNotificationStore.js"
import { BaseButton, BaseIcon } from "@repowerednl/ui-component-library"

const notificationStore = useNotificationStore()

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
})

function hide() {
  notificationStore.remove(props.notification.uid)
}
</script>
