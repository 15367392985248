<template>
  <div class="mx-auto flex max-w-[480px] flex-col items-center gap-4">
    <BaseCard class="flex w-full flex-col gap-4 p-8">
      <TextTitle>Login</TextTitle>
      <!-- Incorrect credentials -->
      <div
        v-if="showCredentialsError"
        class="flex items-center gap-4 rounded-lg bg-danger-100 p-4"
      >
        <BaseIcon
          name="error-warning"
          class="shrink-0 text-2xl text-danger-600"
        />
        <p>De combinatie van e-mailadres en wachtwoord is niet geldig.</p>
      </div>
      <!-- Fix spacing -->
      <form action="" class="flex w-full flex-col gap-4" @submit.prevent>
        <InputField
          v-model="inputEmail"
          label="E-mailadres"
          type="email"
          :state-error="inputError"
        />
        <InputField
          v-model="inputPassword"
          label="Wachtwoord"
          type="password"
          :state-error="inputError"
        />
        <div class="flex flex-row items-baseline justify-between">
          <BaseButton type="submit" color="primary" @click="login">
            Inloggen
          </BaseButton>
          <!-- RouterLink needs to route to wachtwoord vergeten view -->
          <RouterLink :to="{ name: 'forgot-password' }">
            <BaseButton color="subtle" class="text-primary">
              Wachtwoord vergeten
            </BaseButton>
          </RouterLink>
        </div>
      </form>
    </BaseCard>
    <BaseCard class="flex w-full flex-col gap-4 p-8">
      <p class="text-xl font-medium">Nieuw account of vragen?</p>
      <p class="text-sm font-normal">
        Wil je een nieuw account aanmaken of heb je andere vragen, neem dan
        contact op met Repowered.
      </p>
      <a href="mailto:support@repowered.nl">
        <BaseButton color="secondary" class="w-fit">
          Contact opnemen
        </BaseButton>
      </a>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { useRouter } from "vue-router"
import useUserStore from "@/stores/userStore.js"
import {
  TextTitle,
  BaseCard,
  BaseIcon,
  BaseButton,
  InputField,
} from "@repowerednl/ui-component-library"
import { useNotificationStore } from "@/stores/useNotificationStore.js"

const notificationStore = useNotificationStore()
const router = useRouter()
const userStore = useUserStore()
const inputEmail = ref("")
const inputPassword = ref("")
const inputError = ref(false)
const showCredentialsError = ref(false)

function onLoginSuccess() {
  notificationStore.pushToast("Je bent ingelogd")
  router.push({ name: "simulations" })
}

function onLoginUnknown() {
  inputError.value = true
  showCredentialsError.value = true
}

function onLoginFail(error) {
  notificationStore.pushError(
    "Er ging iets mis bij het inloggen",
    `Vernieuw de pagina en probeer het opnieuw (code: ${error.code}).`,
    "login-error",
  )
}

function login() {
  userStore.login(
    inputEmail.value,
    inputPassword.value,
    onLoginSuccess,
    onLoginUnknown,
    onLoginFail,
  )
}

watch([inputEmail, inputPassword], () => {
  inputError.value = false
})
</script>
