<template>
  <BaseCard class="p-6">
    <TextTitle>Energetische situatie</TextTitle>
    <div
      id="energyProfile"
      class="mb-4 mt-4 rounded-xl border border-gray-100 p-4"
    >
      <div class="flex items-center justify-between">
        <TextSubtitle>Energie profiel &ndash; huidige situatie</TextSubtitle>
        <SnapshotButton
          v-if="!demoStore.active"
          id="energyProfile"
          name="Energie profiel – huidige situatie"
        />
      </div>
      <div class="h-[313px]">
        <TimeseriesChart
          chart-type="bar"
          :series="energeticSituation.energetic.series"
          :axes="energeticSituation.energetic.axes"
          :data="energeticSituation.energyProfileData"
          :x-limits="[
            startOfYear(energeticSituation.referenceDate),
            endOfYear(energeticSituation.referenceDate),
          ]"
          time-unit="month"
        />
      </div>
    </div>

    <BaseTabs
      class="energetics-table"
      :tabs="energeticSituation.tabHeaders"
      :update-key="sankeyKey"
      @click="sankeyKey++"
    >
      <template
        v-for="(item, index) in energeticSituation.tableData"
        :key="index"
        #[item.id]
      >
        <div class="p-4">
          <BaseTable
            v-for="(table, tableIndex) in item.df"
            :key="tableIndex"
            class="mb-4 overflow-hidden rounded-lg border"
            :rows="table.rows"
            :columns="table.columns"
          />
          <BaseCard
            v-if="Array.isArray(item.sankeyData)"
            class="rounded-lg border border-gray-100 p-4"
          >
            <SankeyChart
              :key="sankeyKey"
              chart-title="Energiestromen op jaarbasis"
              :chart-data="item.sankeyData"
            />
          </BaseCard>
        </div>
      </template>
    </BaseTabs>

    <div class="mb-4 mt-4 rounded-xl border border-gray-100 p-4">
      <TextSubtitle
        >Winterdag -
        {{ format(winterGraphFirstKeyDate, "d MMM y") }}</TextSubtitle
      >
      <div class="h-[313px]">
        <TimeseriesChart
          chart-type="line"
          :series="energeticSituation.summerWinterGraph.series"
          :axes="energeticSituation.summerWinterGraph.axes"
          :data="energeticSituation.winterProfileData"
        />
      </div>
    </div>
    <div class="mb-4 mt-4 rounded-xl border border-gray-100 p-4">
      <TextSubtitle
        >Zomerdag -
        {{ format(summerGraphFirstKeyDate, "d MMM y") }}</TextSubtitle
      >
      <div class="h-[313px]">
        <TimeseriesChart
          chart-type="line"
          :series="energeticSituation.summerWinterGraph.series"
          :axes="energeticSituation.summerWinterGraph.axes"
          :data="energeticSituation.summerProfileData"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { startOfYear, endOfYear, format } from "date-fns"
import {
  BaseCard,
  BaseTable,
  TextSubtitle,
  TextTitle,
  BaseTabs,
  TimeseriesChart,
} from "@repowerednl/ui-component-library"
import SnapshotButton from "@/components/general/SnapshotButton.vue"
import SankeyChart from "@/components/charts/SankeyChart.vue"
import { ref } from "vue"
import { useEnergeticStore } from "@/stores/useResultsEnergeticSituationStore"
import { useDemoStore } from "@/stores/useDemoStore"

const demoStore = useDemoStore()
const energeticSituation = useEnergeticStore()
const sankeyKey = ref(0)

const summerGraphFirstKeyDate = Object.keys(
  energeticSituation.summerProfileData,
)[0]
const winterGraphFirstKeyDate = Object.keys(
  energeticSituation.winterProfileData,
)[0]
</script>

<style lang="postcss">
.energetics-table {
  table {
    th {
      @apply bg-gray-50 text-sm;
    }

    tr {
      &:last-child {
        td {
          @apply border-none font-bold;
        }
      }
    }

    th:not(:first-child),
    td:not(:first-child) {
      @apply text-right;
      width: 200px;
    }
  }
}
</style>
