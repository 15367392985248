<template>
  <div>
    <TextTitle v-if="chartTitle" class="mb-4 font-black"
      >{{ chartTitle }}
    </TextTitle>
    <div :id="`plotly-${componentId}`" class="relative w-full" />
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue"
import useUid from "@/composables/uid.js"
import { TextTitle } from "@repowerednl/ui-component-library"
import Plotly from "plotly.js-dist-min"
import { formatNumber } from "@/services/formatterService"

const componentId = useUid()

// Define the props
const props = defineProps({
  chartTitle: {
    type: String,
    default: "",
  },
  chartData: {
    type: Object,
    required: true,
  },
})

// Filter out the data with a flow of 0
const filteredData = computed(() => {
  return props.chartData.filter((item) => item.flow > 0)
})

const sankeyLabels = [
  "PV installatie",
  "Onmogelijke levering",
  "Batterij",
  "Net",
  "Curtailment",
  "Verbruik",
]
const sankeyColors = [
  "#FFC700",
  "#FF7F0E",
  "#2CA02C",
  "#C8D3D9",
  "#9467BD",
  "#8C564B",
]

const filteredDataMap = {
  source: filteredData.value.map((item) => sankeyLabels.indexOf(item.from)),
  target: filteredData.value.map((item) => sankeyLabels.indexOf(item.to)),
  value: filteredData.value.map((item) => item.flow),
  color: filteredData.value.map(
    (item) => sankeyColors[sankeyLabels.indexOf(item.from)] + `80`,
  ),
}

const data = {
  type: "sankey",
  orientation: "h",
  node: {
    line: {
      width: 0,
    },
    label: sankeyLabels,
    color: sankeyColors,
    hoverinfo: "none",
  },
  link: {
    source: filteredDataMap.source,
    target: filteredDataMap.target,
    value: filteredDataMap.value,
    color: filteredDataMap.color,
    hoverinfo: "none",
  },
}

const layout = {
  font: {
    size: 14,
  },
  margin: {
    t: 0,
    l: 0,
    r: 0,
    b: 20,
  },
}

// Return the title of the tooltip
function getTooltipTitle(point) {
  if (point.label) {
    return point.label
  }
  return `Van ${point.source.label} naar ${point.target.label}`
}

const tooltipWidth = 230
let tooltipTimeout = null

function createTooltip(data) {
  const dataPointValue = data.points[0]
  const dataEventValue = data.event
  const windowWidth = window.innerWidth

  // Create a tooltip as a div and assign an id
  const tooltip = document.createElement("div")
  tooltip.id = `plotly-tooltip${componentId}`

  document.body.appendChild(tooltip)

  // Set tooltip position based on mouse position
  const shouldOpenToLeft = windowWidth - dataEventValue.clientX < 250
  const tipDirectionClass = shouldOpenToLeft ? "tooltip-left" : "tooltip-right"

  // If it should open to left, subtract tooltip width from mouse position, else add 25px
  tooltip.style.left = shouldOpenToLeft
    ? `${dataEventValue.clientX - 25 - tooltipWidth}px`
    : `${dataEventValue.clientX + 25}px`
  tooltip.style.top = `${dataEventValue.clientY}px`

  // Create tooltip content with template literals
  const tipTitleColor = dataPointValue.color.substring(0, 7)
  const tipTitleText = getTooltipTitle(dataPointValue)
  const tipValueText = `${formatNumber(dataPointValue.value)} kWh`

  // Add classes directly with classList.add
  tooltip.classList.add(
    "absolute",
    "tooltip-container",
    "shadow",
    "-translate-y-1/2",
    "text-base",
    "rounded-lg",
    "bg-white",
    "z-50",
    `w-[${tooltipWidth}px]`,
    tipDirectionClass,
  )

  // Set tooltip content with innerHTML
  tooltip.innerHTML = `
    <div class="flex px-4 py-2 rounded-t-lg text-sm font-medium text-white" style="background-color: ${tipTitleColor};">
      ${tipTitleText}
    </div>
    <div class="px-4 py-2">
      <p class="text-sm">${tipValueText}</p>
    </div>
  `
  // Clear timeout if it exists
  if (tooltipTimeout !== null) clearTimeout(tooltipTimeout)

  // Set visibility of tooltip after a delay
  tooltipTimeout = setTimeout(() => {
    tooltip.style.visibility = "visible"
  }, 50)
}

function deleteTooltip() {
  const tooltip = document.getElementById(`plotly-tooltip${componentId}`)
  if (tooltip) {
    tooltip.remove()
  }
}
// Create the chart when the component is mounted
onMounted(() => {
  Plotly.newPlot(`plotly-${componentId}`, [data], layout, {
    responsive: true,
    displayModeBar: false,
  })

  document
    .getElementById(`plotly-${componentId}`)
    .on("plotly_hover", function (data) {
      createTooltip(data)
    })
    .on("plotly_unhover", function () {
      deleteTooltip()
    })
})
</script>

<style lang="postcss">
.tooltip-container {
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
  &.tooltip-right {
    &::before {
      left: -6px;
      border-right: 6px solid white;
    }
  }
  &.tooltip-left {
    &::before {
      right: -6px;
      border-left: 6px solid white;
    }
  }
}
</style>
