<template>
  <div class="flex justify-evenly xl:justify-center">
    <LoadingWrapper
      :loading="route.params.uid && newSimulationStore.loadingConfiguration"
    >
      <div class="hidden flex-1 xl:block" />
      <div class="flex flex-col items-center gap-4">
        <StepCreateSim
          ref="stepCreateSimRef"
          :is-active="wizardStore.currentStep === INDEX.CREATE_SIM"
          :style="{ order: INDEX.CREATE_SIM }"
          @confirm-btn="() => nextButtonHandler(INDEX.CREATE_SIM)"
          @inactive-click="inactiveClickHandler(INDEX.CREATE_SIM)"
        />
        <StepConnection
          ref="stepConnectionRef"
          :is-active="wizardStore.currentStep === INDEX.CONNECTION"
          :style="{ order: INDEX.CONNECTION }"
          @confirm-btn="nextButtonHandler(INDEX.CONNECTION)"
          @back-btn="backButtonHandler(INDEX.CONNECTION)"
          @inactive-click="inactiveClickHandler(INDEX.CONNECTION)"
        />
        <StepData
          ref="stepDataRef"
          :is-active="wizardStore.currentStep === INDEX.DATA"
          :style="{ order: INDEX.DATA }"
          @confirm-btn="nextButtonHandler(INDEX.DATA)"
          @back-btn="backButtonHandler(INDEX.DATA)"
          @inactive-click="inactiveClickHandler(INDEX.DATA)"
        />
        <StepConsumption
          ref="stepConsumptionRef"
          :is-active="wizardStore.currentStep === INDEX.CONSUMPTION"
          :style="{ order: INDEX.CONSUMPTION }"
          :is-enabled="!wizardValidationStore.disabledSteps[INDEX.CONSUMPTION]"
          @confirm-btn="nextButtonHandler(INDEX.CONSUMPTION)"
          @back-btn="backButtonHandler(INDEX.CONSUMPTION)"
          @inactive-click="inactiveClickHandler(INDEX.CONSUMPTION)"
        />
        <StepGenerate
          ref="stepGenerateRef"
          :is-active="wizardStore.currentStep === INDEX.GENERATE"
          :style="{ order: INDEX.GENERATE }"
          :is-enabled="!wizardValidationStore.disabledSteps[INDEX.GENERATE]"
          @confirm-btn="nextButtonHandler(INDEX.GENERATE)"
          @back-btn="backButtonHandler(INDEX.GENERATE)"
          @inactive-click="inactiveClickHandler(INDEX.GENERATE)"
        />
        <StepCalculation
          ref="stepCalculationRef"
          :is-active="wizardStore.currentStep === INDEX.CALCULATION"
          :style="{ order: INDEX.CALCULATION }"
          :is-enabled="!wizardValidationStore.disabledSteps[INDEX.CALCULATION]"
          @confirm-btn="nextButtonHandler(INDEX.CALCULATION)"
          @back-btn="backButtonHandler(INDEX.CALCULATION)"
          @inactive-click="inactiveClickHandler(INDEX.CALCULATION)"
        />
        <StepBattery
          ref="stepBatteryRef"
          :is-active="wizardStore.currentStep === INDEX.BATTERY"
          :style="{ order: INDEX.BATTERY }"
          :is-enabled="!!newSimulationStore.dataframes"
          :disabled-next-button="!wizardValidationStore.allStepsValid"
          @confirm-btn="confirmButtonHandler"
          @back-btn="() => backButtonHandler(INDEX.BATTERY)"
          @inactive-click="inactiveClickHandler(INDEX.BATTERY)"
        />
      </div>
      <div class="flex justify-center xl:flex-1">
        <div class="relative flex flex-col">
          <VerticalStepper
            v-model="stepperIndex"
            :items="stepperItems"
            class="sticky top-0 py-6"
          />
        </div>
      </div>
    </LoadingWrapper>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useWizardStore } from "@/stores/wizardStore"
import useNewSimulationStore from "@/stores/newSimulationStore"
import { useNotificationStore } from "@/stores/useNotificationStore"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import {
  VerticalStepper,
  LoadingWrapper,
} from "@repowerednl/ui-component-library"
import StepData from "@/components/wizard/StepData.vue"
import StepCreateSim from "@/components/wizard/StepCreateSim.vue"
import StepConnection from "@/components/wizard/StepConnection.vue"
import StepCalculation from "@/components/wizard/StepCalculation.vue"
import StepBattery from "@/components/wizard/StepBattery.vue"
import StepConsumption from "@/components/wizard/StepConsumption.vue"
import StepGenerate from "@/components/wizard/StepGenerate.vue"

const wizardValidationStore = useWizardValidationStore()
const notificationStore = useNotificationStore()
const wizardStore = useWizardStore()
const newSimulationStore = useNewSimulationStore()
const route = useRoute()
const router = useRouter()
const stepCreateSimRef = ref()
const stepConnectionRef = ref()
const stepDataRef = ref()
const stepConsumptionRef = ref()
const stepGenerateRef = ref()
const stepCalculationRef = ref()
const stepBatteryRef = ref()
const INDEX = wizardValidationStore.INDEX

const stepperItems = computed(() =>
  [
    {
      label: `${INDEX.CREATE_SIM}. Simulatie aanmaken`,
      done: stepCreateSimRef.value?.getValidationState === "valid",
      error: stepCreateSimRef.value?.getValidationState === "error",
      disabled: stepCreateSimRef.value?.getValidationState === "",
    },
    {
      label: `${INDEX.CONNECTION}. Elektriciteitsaansluiting`,
      done: stepConnectionRef.value?.getValidationState === "valid",
      error: stepConnectionRef.value?.getValidationState === "error",
      disabled: stepConnectionRef.value?.getValidationState === "",
    },
    {
      label: `${INDEX.DATA}. Eigen data`,
      done: stepDataRef.value?.getValidationState === "valid",
      error: stepDataRef.value?.getValidationState === "error",
      disabled: stepDataRef.value?.getValidationState === "",
    },
    {
      label: `${INDEX.CONSUMPTION}. Verbruik`,
      done: stepConsumptionRef.value?.getValidationState === "valid",
      error: stepConsumptionRef.value?.getValidationState === "error",
      disabled: stepConsumptionRef.value?.getValidationState === "",
    },
    {
      label: `${INDEX.GENERATE}. Opwek`,
      done: stepGenerateRef.value?.getValidationState === "valid",
      error: stepGenerateRef.value?.getValidationState === "error",
      disabled: stepGenerateRef.value?.getValidationState === "",
    },
    {
      label: `${INDEX.CALCULATION}. Berekening`,
      done: stepCalculationRef.value?.getValidationState === "valid",
      error: stepCalculationRef.value?.getValidationState === "error",
      disabled: stepCalculationRef.value?.getValidationState === "",
    },
    {
      label: `${INDEX.BATTERY}. Batterij`,
      done: stepBatteryRef.value?.getValidationState === "valid",
      error: stepBatteryRef.value?.getValidationState === "error",
      disabled: stepBatteryRef.value?.getValidationState === "",
    },
  ].sort((a, b) => a.label.localeCompare(b.label)),
)

/**
 * Converts the stepper index (0-indexed) to the current step (1-indexed).
 */
const stepperIndex = computed({
  get: () => wizardStore.currentStep - 1,
  set: (value) => (wizardStore.currentStep = value + 1),
})

function nextButtonHandler(currentStep) {
  const steps = Object.entries(INDEX)
  const currentIndex = steps.findIndex(([, value]) => value === currentStep)
  const nextStep = steps[currentIndex + 1]?.[0]

  if (nextStep === "BATTERY" && !newSimulationStore.dataframes) {
    return
  }

  if (!wizardValidationStore.disabledSteps[INDEX[nextStep]]) {
    wizardStore.currentStep = INDEX[nextStep]
  } else {
    nextButtonHandler(INDEX[nextStep])
  }
}

function backButtonHandler(currentStep) {
  const steps = Object.entries(INDEX)
  const currentIndex = steps.findIndex(([, value]) => value === currentStep)
  const previousStep = steps[currentIndex - 1]?.[0] // Selector of the previous step

  if (!wizardValidationStore.disabledSteps[INDEX[previousStep]]) {
    wizardStore.currentStep = INDEX[previousStep]
  } else {
    backButtonHandler(INDEX[previousStep])
  }
}

function confirmButtonHandler() {
  newSimulationStore.submitSimulation(
    route.name === "wizard-edit",
    () => {
      if (route.name === "edit-simulation") {
        notificationStore.pushToast(
          "De simulatie is bijgewerkt",
          "De berekeningen worden opnieuw uitgevoerd. Bekijk de status in het overzicht.",
        )
      } else {
        notificationStore.pushToast(
          "De simulatie is ingediend",
          "Bekijk de status in het overzicht.",
        )
      }
      wizardStore.$reset()
      newSimulationStore.$reset()
      router.push({ name: "simulations" })
    },
    (error) =>
      notificationStore.pushError(
        "Er ging iets mis bij het indienen van de simulatie",
        `Controleer of alle stappen correct zijn ingevuld en probeer het opnieuw (code: ${error.code}).`,
        "simulation-submit-error",
      ),
  )
}

function inactiveClickHandler(index) {
  wizardStore.currentStep = index
  return wizardStore.currentStep
}

/**
 * Process possible data from the route to load initial configuration.
 */
onMounted(() => {
  // Reset all stores and validation rules
  wizardStore.$reset()
  newSimulationStore.$reset()
  Object.values(wizardValidationStore.validationRules).forEach((rule) =>
    rule.$reset(),
  )
  // Scroll to URL query step
  if (route.query.step) {
    const step = parseInt(route.query.step)
    if (!wizardValidationStore.disabledSteps[step]) {
      wizardStore.currentStep = step
    } else if (step === INDEX.CONSUMPTION || step === INDEX.GENERATE) {
      wizardStore.currentStep = INDEX.DATA
    } else {
      wizardStore.currentStep = INDEX.CREATE_SIM
    }
  }
  // Load data from config if on edit route
  if (route.params.uid) {
    newSimulationStore.uid = route.params.uid
    newSimulationStore.getConfiguration(
      route.params.uid,
      (config) => {
        wizardStore.loadConfiguration(config)
        Object.values(wizardValidationStore.validationRules).forEach((rule) =>
          rule.$validate(),
        )
      },
      () => {
        notificationStore.pushError(
          "Simulatie niet gevonden",
          `De simulatie met ID '${route.params.uid}' bestaat niet of is niet toegankelijk.`,
          "simulation-not-found",
        )
        router.push({ name: "simulations" })
      },
      (error) => {
        notificationStore.pushError(
          "Fout bij ophalen van simulatie",
          `Er is een fout opgetreden bij het ophalen van de simulatie (code: ${error.code}).`,
          "simulation-fetch-error",
        )
        router.push({ name: "simulations" })
      },
    )
  }
})
</script>
