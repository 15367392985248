import { read, utils } from "xlsx"

export async function excelToArray(file) {
  const data = await file.arrayBuffer()
  const workbook = read(data, { cellDates: true, dense: false })
  const worksheet = workbook.Sheets[workbook.SheetNames[0]]
  return utils.sheet_to_json(worksheet, {
    header: 1,
    raw: false,
    dateNF: "dd-mm-yyyy h:mm:ss",
  })
}
