<template>
  <nav class="flex items-center border-b border-gray-100 bg-white">
    <div class="flex-1">
      <router-link
        :to="link"
        class="flex h-16 w-fit cursor-pointer flex-col justify-center px-4 py-1 text-primary hover:bg-gray-100"
      >
        <img
          id="logo"
          :alt="`${theme} logo`"
          class="max-h-6 w-auto"
          :src="`/assets/theme/${theme}/logo.svg`"
        />

        <p class="mt-1 text-xs font-medium uppercase text-gray-900">
          {{ logoSubtitle }}
        </p>
      </router-link>
    </div>
    <p>{{ title }}</p>
    <div class="flex flex-1 items-center justify-end gap-4 px-4">
      <template v-if="isAuthenticated">
        <span class="font-medium">
          <p>{{ userName }}</p>
        </span>
        <div ref="menuButtonRef">
          <BaseButton
            data-cy="menu-button"
            color="secondary"
            small
            @click="toggleMenu($event)"
          >
            <BaseIcon name="menu" class="mr-1 text-lg" />
            <span>Menu</span>
          </BaseButton>
        </div>
      </template>
    </div>
    <Transition>
      <div
        v-if="showMenu"
        ref="menuRef"
        data-cy="menu-container"
        class="absolute right-0 top-16 z-50 flex min-w-[250px] flex-col gap-2 rounded-md border border-gray-100 bg-white p-4 shadow-md"
        @keyup.esc="close"
      >
        <router-link
          v-for="(item, index) in menuItems"
          :key="index"
          :data-cy="`link-${item.name}`"
          :to="`/${item.name}`"
          @click="close"
        >
          <div v-if="item.name === 'news'" class="relative flex items-center">
            <!-- Ping indicator -->
            <div
              v-if="changelogStore.notify"
              class="absolute left-2 top-3 size-2 rounded-full bg-danger"
            >
              <div class="size-full animate-ping rounded-full bg-danger" />
            </div>
          </div>
          <div
            class="box-border flex cursor-pointer items-center rounded-md border border-gray-200 bg-white p-2 hover:bg-gray-100 active:bg-gray-200"
          >
            <BaseIcon :name="item.icon" />
            <p class="mx-auto font-medium">{{ item.text }}</p>
          </div>
        </router-link>
        <div
          data-cy="link-logout"
          class="flex cursor-pointer items-center rounded-md border border-gray-200 bg-white p-2 hover:bg-gray-100"
          @click="logoutClick"
        >
          <BaseIcon name="logout-box" />
          <p class="mx-auto font-medium">Uitloggen</p>
        </div>
      </div>
    </Transition>
  </nav>
</template>
<script setup>
import { BaseButton, BaseIcon } from "@repowerednl/ui-component-library"
import { ref, onMounted, onBeforeUnmount } from "vue"
import useChangelogStore from "@/stores/changelogStore.js"

defineProps({
  link: {
    type: Object,
    default: () => ({
      name: "simulations",
    }),
  },
  title: {
    type: String,
    default: "",
  },
  theme: {
    type: String,
    default: "repowered",
  },
  logoSubtitle: {
    type: String,
    default: "Battery Simulation Tool",
  },
  userName: {
    type: String,
    default: "",
  },
  isAuthenticated: {
    type: Boolean,
    required: true,
  },
  menuItems: {
    type: Array,
    default: () => [],
  },
})

const showMenu = ref(false)
const emit = defineEmits(["logout"])
const menuRef = ref(null)
const menuButtonRef = ref(null)
const changelogStore = useChangelogStore()

function close() {
  if (showMenu.value) {
    showMenu.value = false
    menuButtonRef.value.focus()
  }
}

function toggleMenu() {
  showMenu.value = !showMenu.value
}

/**
 * Detects clicks outside the specified elements and triggers a callback when a click occurs outside.
 * This differs from the existing clickOutside function by tracking two elements.
 *
 * @param {ref} elementRef - A Vue ref that refers to the primary DOM element to monitor.
 * @param {ref} secondElementRef - A Vue ref that refers to the second DOM element to exclude from outside clicks.
 * @param {Function} callback - The callback function to execute when a click is detected outside the elements.
 */
function useClickOutside(elementRef, secondElementRef, callback) {
  const clickOutsideHandler = (event) => {
    if (
      elementRef.value &&
      !elementRef.value.contains(event.target) &&
      showMenu.value &&
      !secondElementRef.value.contains(event.target)
    ) {
      callback()
    }
  }

  onMounted(() => {
    document.addEventListener("click", clickOutsideHandler)
  })

  onBeforeUnmount(() => {
    document.removeEventListener("click", clickOutsideHandler)
  })
}

function logoutClick() {
  emit("logout")
  close()
}

useClickOutside(menuRef, menuButtonRef, close)
</script>

<style scoped>
.v-enter-active {
  transition: opacity 0.2s ease;
}

.v-enter-from {
  opacity: 0;
}

/* No transition on leaving */
.v-leave-active {
  transition: none;
}

.v-leave-to {
  opacity: 1;
}
</style>
