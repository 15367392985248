<template>
  <div
    class="mx-auto mt-6 flex max-w-[480px] flex-col items-center gap-4 md:w-[480px]"
  >
    <BaseCard class="flex w-full flex-col gap-4 p-6">
      <TextTitle>Wachtwoord wijzigen</TextTitle>
      <div
        v-if="tokenInvalid || cannotChangePassword || passwordsMatch"
        class="flex items-center gap-4 rounded-lg bg-danger-100 p-4"
      >
        <BaseIcon
          name="error-warning"
          class="shrink-0 text-2xl text-danger-600"
        />
        <p v-if="tokenInvalid">
          De link is ongeldig of verlopen. Vraag een nieuwe link aan.
        </p>
        <p v-else-if="cannotChangePassword">
          Er ging iets mis bij het wijzigen van het wachtwoord. Probeer het
          later opnieuw.
        </p>
        <p v-else-if="passwordsMatch">
          De wachtwoorden komen niet overeen. Probeer het opnieuw.
        </p>
      </div>
      <!-- Password instructions -->
      <p>
        Kies een wachtwoord dat je nergens anders gebruikt met de volgende
        eigenschappen:
      </p>
      <ul class="list-inside list-disc">
        <li>Bevat minimaal 8 tekens</li>
        <li>Mag niet enkel uit cijfers bestaan</li>
        <li>Geen veelvoorkomend wachtwoord</li>
      </ul>
      <form class="flex w-full flex-col gap-4" @submit.prevent>
        <InputField
          v-model="form.password"
          type="password"
          label="Wachtwoord"
          :disabled="tokenInvalid || cannotValidateToken"
        />
        <InputField
          v-model="form.passwordConfirm"
          label="Wachtwoord herhalen"
          type="password"
          :disabled="tokenInvalid || cannotValidateToken"
          :state-error="passwordIllegal"
          :helper-text="
            passwordIllegal ? 'Wachtwoord voldoet niet aan de eisen' : null
          "
        />
        <BaseButton
          :disabled="tokenInvalid || cannotValidateToken"
          color="primary"
          class="w-fit"
          @click="onSubmit"
        >
          Wijzig wachtwoord
        </BaseButton>
      </form>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import { postApiJson, fetchApiJson } from "@/services/bstApiService.js"
import { useNotificationStore } from "@/stores/useNotificationStore.js"
import {
  BaseCard,
  TextTitle,
  InputField,
  BaseButton,
  BaseIcon,
} from "@repowerednl/ui-component-library"

const router = useRouter()
const route = useRoute()
const notificationStore = useNotificationStore()

const form = ref({
  password: "",
  passwordConfirm: "",
})

const passwordsMatch = ref(false)

// When there is no token in the URL, return false and show error
const tokenInvalid = ref(false)
const cannotValidateToken = ref(false)
const passwordIllegal = ref(false)

// When the token is invalid, show the error
const cannotChangePassword = ref(false)

/**
 * Submit the form to change the password
 */
function onSubmit() {
  passwordsMatch.value = form.value.password !== form.value.passwordConfirm
  if (passwordsMatch.value || tokenInvalid.value || cannotValidateToken.value)
    return

  postApiJson(`/v2/user/change-password/`, {
    password_reset_token: route.query.token,
    new_password: form.value.password,
  })
    .then(() => {
      router.push({ name: "login" })
    })
    .catch((error) => {
      cannotChangePassword.value = true
      if (error.code === 400) {
        passwordIllegal.value = true
      } else if (error.code === 401) {
        tokenInvalid.value = true
      } else {
        notificationStore.pushError(
          "Er ging iets mis bij het wijzigen van het wachtwoord",
          `Probeer het later opnieuw (code: ${error.code}).`,
          "password-change-error",
        )
      }
    })
}

/**
 * Validate the token in the URL
 */
function validateToken() {
  fetchApiJson(
    `/v2/user/validate-password-reset-token/?password_reset_token=${route.query.token}`,
  ).catch((error) => {
    cannotValidateToken.value = true
    if (error.code === 401) {
      tokenInvalid.value = true
    } else {
      notificationStore.pushError(
        "Er ging iets mis bij het valideren van de link",
        `Probeer het later opnieuw (code: ${error.code}).`,
        "token-validation-error",
      )
    }
  })
}

onMounted(() => {
  if (!route.query.token) {
    tokenInvalid.value = true
    return
  }
  validateToken()
})
</script>
