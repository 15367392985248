<template>
  <div
    v-show="show"
    v-click-outside="close"
    class="context-menu absolute z-10 w-56 overflow-hidden rounded-lg bg-white shadow"
  >
    <div
      v-for="item in items"
      :key="item.label"
      class="flex cursor-pointer gap-2 px-4 py-2 hover:bg-gray-100"
      :class="
        item.highlight
          ? 'border-t border-gray-200 text-danger first:border-0'
          : ''
      "
      @click.stop="runAction(item.action)"
    >
      <BaseIcon :name="item.icon" filled class="text-xl" />
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
import vClickOutside from "@/composables/clickOutside"
import { BaseIcon } from "@repowerednl/ui-component-library"

const show = ref(false)

defineProps({
  items: {
    type: Array,
    required: true,
  },
})

defineExpose({ open, close })

function open() {
  show.value = true
}

function close() {
  show.value = false
}

function runAction(action) {
  close()
  action()
}
</script>
