import { defineStore } from "pinia"
import {
  fetchApiCatch,
  fetchApiJson,
  postApiJson,
} from "@/services/bstApiService.js"

export default defineStore("simulationsStore", {
  state: () => ({
    loading: false,
    loadingFailed: false,
    simulations: [],
  }),
  actions: {
    loadSimulations(onSuccess, onFail) {
      this.loading = true
      fetchApiJson("/v2/simulations/")
        .then((data) => {
          this.simulations = data
          this.loadingFailed = false
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          this.loadingFailed = true
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    delete(uid, onSuccess, onFail) {
      fetchApiJson(`/v2/simulations/${uid}/`, { method: "DELETE" })
        .then(() => {
          this.loadSimulations()
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
    duplicate(uid, onSuccess, onFail) {
      postApiJson(`/v2/simulations/${uid}/duplicate/`)
        .then(() => {
          this.loadSimulations()
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
    rename(uid, newName, onSuccess, onFail) {
      fetchApiCatch(`/v2/simulations/${uid}/rename/?new_name=${newName}`, {
        method: "PUT",
      })
        .then(() => {
          this.loadSimulations()
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
  },
})
