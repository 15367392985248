import { createRouter, createWebHistory } from "vue-router"
import useUserStore from "@/stores/userStore.js"
import LoginView from "@/views/LoginView.vue"
import ForgotPasswordView from "@/views/ForgotPasswordView.vue"
import SimulationsView from "@/views/SimulationsView.vue"
import ResultsView from "@/views/ResultsView.vue"
import WizardView from "@/views/WizardView.vue"
import ChangePasswordView from "@/views/ChangePasswordView.vue"
import demoExpiredView from "@/views/DemoExpiredView.vue"
import { useDemoStore } from "@/stores/useDemoStore"
import { useNotificationStore } from "@/stores/useNotificationStore"
import NewsView from "@/views/NewsView.vue"

const demoName = "demo-expired"

function simulationsDepleted() {
  const notificationStore = useNotificationStore()
  notificationStore.pushContentToast(
    {
      title: `Maximaal aantal simulaties bereikt`,
      message: "Klopt dit niet? Neem dan contact op.",
      action: () => (window.location.href = "mailto:support@repowered.nl"),
      actionLabel: "Contact",
    },
    "simulations-depleted",
  )
}

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/forgot-password",
    alias: "/welcome",
    name: "forgot-password",
    component: ForgotPasswordView,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePasswordView,
  },
  {
    path: "/simulations",
    alias: "/",
    name: "simulations",
    component: SimulationsView,
  },
  {
    path: "/results/:uid",
    name: "results",
    component: ResultsView,
  },
  {
    path: "/wizard",
    name: "wizard",
    redirect: { name: "wizard-create" },
    component: WizardView,
    beforeEnter: (_to, _from, next) => {
      const demoStore = useDemoStore()
      if (demoStore.simulation_quotum_exceeded) {
        simulationsDepleted()
        next({ name: "simulations" })
      } else {
        next()
      }
    },
    children: [
      {
        path: "create",
        name: "wizard-create",
        component: WizardView,
      },
      {
        path: "edit/:uid",
        name: "wizard-edit",
        component: WizardView,
      },
    ],
  },
  {
    path: "/demo-expired",
    name: demoName,
    component: demoExpiredView,
  },
  {
    path: "/news",
    alias: "/contact",
    name: "news",
    component: NewsView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "simulations" },
  },
]

export const onlyPublicRoutes = [
  "login",
  "forgot-password",
  "change-password",
  "demo-expired",
]
export const publicRoutes = [...onlyPublicRoutes]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to) => {
  const userStore = useUserStore()
  const demoStore = useDemoStore()

  const isLoggedInWithExpiredDemo =
    userStore.isAuthenticated && demoStore.ended && demoStore.active
  const isNotOnPublicRoute = !publicRoutes.includes(to.name)
  const isUnauthorized =
    !publicRoutes.includes(to.name) && !userStore.isAuthenticated
  const isAuthenticatedAndOnPublicRoute =
    onlyPublicRoutes.includes(to.name) && userStore.isAuthenticated

  if (isLoggedInWithExpiredDemo && isNotOnPublicRoute) {
    userStore.logout()
    return { name: demoName }
  }

  if (isUnauthorized) {
    return { name: "login" }
  }

  if (isAuthenticatedAndOnPublicRoute) {
    return { name: "simulations" }
  }
  // No redirect needed
  return undefined
})

export default router
