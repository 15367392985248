import { defineStore } from "pinia"
import { ref } from "vue"

export const useThemeStore = defineStore(
  "theme",
  () => {
    const defaultTheme = "repowered"
    const theme = ref(null)

    if (!theme.value) {
      theme.value = defaultTheme
    }

    function resetTheme() {
      theme.value = defaultTheme
    }

    return {
      theme,
      resetTheme,
    }
  },
  { persist: true },
)
