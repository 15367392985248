<template>
  <WizardStep
    ref="wizardStepRef"
    label="Stap 2"
    title="Elektriciteitsaansluiting"
    :is-active="isActive"
    :is-complete="!wizardValidationStore.connectionStepInvalid"
    :is-enabled="isEnabled"
    :validation-state="wizardValidationStore.v$connectionRules"
    @confirm-btn="wizardValidationStore.v$connectionRules.$validate()"
  >
    <template #content>
      <InputField
        v-model="wizardStore.grid.importCapacity"
        data-cy="grid-import-capacity"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$connectionRules.grid.importCapacity,
            'Teruglevercapaciteit',
          )
        "
        :state-error="
          !!wizardValidationStore.v$connectionRules.grid.importCapacity.$errors
            .length
        "
        label="Teruglevercapaciteit"
        placeholder="Bijv. 10.000"
        type="number"
        @update:model-value="
          wizardValidationStore.v$connectionRules.grid.importCapacity.$touch()
        "
      >
        <template #labelSuffix>
          <WikiTooltipComponent path="/bst/wizard/feedin-capacity" />
        </template>
        <template #addonRight> kW </template>
      </InputField>

      <InputField
        v-model="wizardStore.grid.exportCapacity"
        data-cy="grid-export-capacity"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$connectionRules.grid.exportCapacity,
            'Afnamecapaciteit',
          )
        "
        :state-error="
          !!wizardValidationStore.v$connectionRules.grid.exportCapacity.$errors
            .length
        "
        label="Afnamecapaciteit"
        placeholder="Bijv. 2.000"
        type="number"
        @update:model-value="
          wizardValidationStore.v$connectionRules.grid.exportCapacity.$touch()
        "
      >
        <template #labelSuffix>
          <WikiTooltipComponent path="/bst/wizard/takeoff-capacity" />
        </template>
        <template #addonRight> kW </template>
      </InputField>
      <div class="flex flex-col gap-1">
        <InputSelect
          v-model="wizardStore.grid.operator"
          data-cy="grid-operator"
          :helper-text="
            vuelidateErrors(
              wizardValidationStore.v$connectionRules.grid.operator,
              'Netbeheerder',
            )
          "
          :options="operatorOptions"
          :state-error="
            !!wizardValidationStore.v$connectionRules.grid.operator.$errors
              .length
          "
          track-by="name"
          select-title="Netbeheerder"
          label="name"
          placeholder="Kies netbeheerder"
        >
          <template #labelSuffix>
            <WikiTooltipComponent path="/bst/wizard/grid-operator" />
          </template>
        </InputSelect>
      </div>
    </template>
    <template #summary>
      {{ formatNumber(wizardStore.grid.importCapacity, 0) }}&nbsp;kW
      &bull;&nbsp;{{ formatNumber(wizardStore.grid.exportCapacity, 0) }}&nbsp;kW
      &bull;&nbsp;{{ wizardStore.grid.operator.name }}
    </template>
  </WizardStep>
</template>

<script setup>
import { ref, computed } from "vue"
import { formatNumber } from "@/services/formatterService"
import { operatorOptions, useWizardStore } from "@/stores/wizardStore"
import { InputField, InputSelect } from "@repowerednl/ui-component-library"
import WizardStep from "@/components/wizard/WizardStep.vue"
import { vuelidateErrors } from "@/helpers/vuelidate-errors"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const wizardValidationStore = useWizardValidationStore()

const wizardStore = useWizardStore()
const wizardStepRef = ref()

const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)

defineExpose({ getValidationState })

defineProps({
  /*
    Used to determine which step is currently active
    */
  isActive: {
    type: Boolean,
    required: true,
  },
  /*
    For determining wether to show the finished checkmark and summary of the inputfields.
    */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /*
    Used to determine if the step is enabled
    */
  isEnabled: {
    type: Boolean,
    default: true,
  },
})
</script>
