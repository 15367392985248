export default {
  beforeMount(el, binding) {
    function clickOutsideEvent(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value()
      }
    }
    el.clickOutsideEvent = clickOutsideEvent
    // Set capture to true to fire the event in the outermost element first
    document.addEventListener("click", clickOutsideEvent, true)
  },
  unmounted(el) {
    document.removeEventListener("click", el.clickOutsideEvent, true)
  },
}
