<template>
  <div>
    <component
      :is="demoStore.active ? BaseTooltip : 'div'"
      description="Niet beschikbaar tijdens demo"
    >
      <BaseButton
        color="outline-dark"
        :loading="disabledButton"
        :disabled="demoStore.active"
        @click="generatePdf"
      >
        <div class="flex items-center">
          <p>PDF Downloaden</p>
          <BaseIcon class="ml-2 text-lg" name="download-2" filled />
        </div>
      </BaseButton>
    </component>
    <ResultsPdf v-if="showReportPdf" />
  </div>
</template>

<script setup>
import { computed, ref } from "vue"
import {
  BaseButton,
  BaseIcon,
  BaseTooltip,
} from "@repowerednl/ui-component-library"

import { useResultsStore } from "@/stores/useResultsStore"
import jsPDF from "jspdf"
import * as htmlToImage from "html-to-image"
import ResultsPdf from "@/components/ResultsPdf.vue"
import { useDemoStore } from "@/stores/useDemoStore"

const demoStore = useDemoStore()
const resultsStore = useResultsStore()
const pdfContainers = [
  "pageOne",
  "pageTwo",
  "pageThree",
  "pageFour",
  "pageFive",
  "pageSix",
]
const showReportPdf = ref(false)
// Download state for the button
const pdfIsDownloading = ref(false)
const disabledButton = computed(() => {
  if (pdfIsDownloading.value) return true
  return resultsStore.loading
})
const caseName = computed(() => resultsStore.results?.simulation_summary.name)

// Create the function to generate pdf
const generatePdf = async () => {
  if (demoStore.active) return
  showReportPdf.value = true
  pdfIsDownloading.value = true
  const doc = new jsPDF("a4", "", "", true)
  const pdfWidth = doc.internal.pageSize.getWidth() - 6
  const logo = document.getElementById("logo")

  // Convert logo to image
  let logoData
  let logoWidth = 0
  let logoHeight = 0
  if (logo) {
    const logoCanvas = await htmlToImage.toCanvas(logo)
    logoData = logoCanvas.toDataURL("image/png")
    logoWidth = logoCanvas.width
    logoHeight = logoCanvas.height
  }

  // Go through all the containers and convert them to images and add them to the pdf.
  for (let index = 0; index < pdfContainers.length; index++) {
    const containerId = pdfContainers[index]
    const container = document.getElementById(containerId)

    await htmlToImage.toCanvas(container).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpg")
      const imgAspectRatio = canvas.height / canvas.width
      const imgHeight = pdfWidth * imgAspectRatio
      if (index > 0) doc.addPage()

      // Add logo to the page
      if (logoData) {
        let pdfLogoWidth = 40 // width in mm
        let pdfLogoHeight = (logoHeight * pdfLogoWidth) / logoWidth
        doc.addImage(logoData, "PNG", 166, 5, pdfLogoWidth, pdfLogoHeight)
      }

      if (index === 0) {
        doc.text(`${caseName.value}`, 3, 8)
      }
      doc.addImage(imgData, "PNG", 3, 11, pdfWidth, imgHeight) // adjust parameters accordingly
    })
    container?.classList.remove("!border-opacity-0")
  }
  doc.save(`Resultaten - ${caseName.value}.pdf`)
  pdfIsDownloading.value = false
  showReportPdf.value = false
}
</script>
