import { defineStore } from "pinia"
import { postApiJson, fetchApiJson } from "@/services/bstApiService"
import { useThemeStore } from "@/stores/use-theme-store"
import { useNotificationStore } from "@/stores/useNotificationStore"
import * as Sentry from "@sentry/browser"
import { organisationThemes } from "@/services/themeService"

export default defineStore("userStore", {
  state: () => ({
    username: null,
    accessToken: null,
    refreshToken: null,
    loading: false,
    isAuthenticated: false,
  }),
  actions: {
    setUser(username, organisation, accessToken, refreshToken) {
      this.username = username
      this.accessToken = accessToken
      this.refreshToken = refreshToken
      useThemeStore().theme = organisationThemes.includes(organisation)
        ? organisation
        : "repowered"
    },
    setAuthenticated(isAuthenticated) {
      this.isAuthenticated = Boolean(isAuthenticated)
      if (this.isAuthenticated) {
        Sentry.setUser({ email: this.username })
      } else {
        Sentry.setUser(null)
      }
    },
    login(username, password, onSuccess, onUnknown, onFail) {
      this.loading = true
      postApiJson("/v2/user/login/", { username, password })
        .then(async (data) => {
          this.setUser(
            username,
            data.organisation,
            data.access_token,
            data.refresh_token,
          )
          this.setAuthenticated(true)
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (error.code === 401) {
            if (onUnknown) onUnknown(error)
          } else {
            if (onFail) onFail(error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    logout() {
      this.setUser(null, null, null)
      this.setAuthenticated(false)
      const themeStore = useThemeStore()
      themeStore.resetTheme()
    },
    async refreshAccessToken(returnOnSuccess, returnOnFail) {
      this.loading = true
      return await fetchApiJson("/v2/user/refresh-access-token/")
        .then((data) => {
          this.accessToken = data.access_token
          this.setAuthenticated(true)
          return returnOnSuccess()
        })
        .catch(() => {
          console.error("Failed to fetch new access token, logging out")
          this.logout()
          useNotificationStore().pushToast(
            "De sessie is verlopen",
            "Log opnieuw in om verder te gaan.",
            "refresh-token-expired",
          )
          return returnOnFail()
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  persist: {
    paths: ["username", "refreshToken", "isAuthenticated"],
  },
})
